import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AnimalService} from '../../shared/services/animal.service';
import {SelectOptionFormatService} from '../../shared/services/helpers/select-option-format.service';
import {TranslateService} from '@ngx-translate/core';
import {ReferenceService} from '../../shared/services/reference.service';
import {Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {ReCaptchaKey} from '../../shared/const/reCaptchaKey';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-animal-check',
    templateUrl: './animal-check.component.html'
})
export class AnimalCheckComponent implements OnInit, OnDestroy {

    checkForm: FormGroup;
    species: any;
    tagNumberPrefix = '';
    codeLength = 0;
    animalData: any;
    subs: Subscription;
    subs2: Subscription;
    siteKey = ReCaptchaKey.siteKey;

    constructor(public translate: TranslateService,
                private animalService: AnimalService,
                private referenceService: ReferenceService,
                private reCaptchaV3Service: ReCaptchaV3Service,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    async ngOnInit() {
        await this.referenceService.getAllReferences();
        const refSpecies = await this.referenceService.getReferenceList('species');
        this.species = SelectOptionFormatService.getOptions(refSpecies);

        this.checkForm = new FormGroup({
            speciesId: new FormControl(null, Validators.required),
            tagNumber: new FormControl(null, Validators.required),
            isImported: new FormControl(false)
        });

        this.subs = this.checkForm.get('speciesId').valueChanges.subscribe(id => {
            this.tagNumberPrefix = '';
            this.codeLength = 0;

            if (this.checkForm.value.isImported) {
                this.checkForm.get('tagNumber').setValidators([Validators.required]);
                this.checkForm.get('tagNumber').updateValueAndValidity();
                this.codeLength = 50;
                this.tagNumberPrefix = '';
                return;
            }

            this.checkForm.get('tagNumber').reset();
            this.updateValidators(id);
        });

        this.subs2 = this.checkForm.get('isImported').valueChanges.subscribe(checked => {
            if (checked) {
                this.checkForm.get('tagNumber').setValidators([Validators.required]);
                this.checkForm.get('tagNumber').updateValueAndValidity();
                this.checkForm.get('speciesId').setValidators(null);
                this.checkForm.get('speciesId').updateValueAndValidity();
                this.codeLength = 50;
                this.tagNumberPrefix = '';
                return;
            } else {
                this.checkForm.get('speciesId').setValidators([Validators.required]);
                this.checkForm.get('speciesId').updateValueAndValidity();
                this.updateValidators(this.checkForm.value.speciesId);
            }
        });

        const queryParams = this.activatedRoute.snapshot.queryParams;
        if (Object.keys(queryParams).length > 0) {
            const isImported = queryParams.isImported ? queryParams.isImported.toLocaleLowerCase() === 'true' : false;
            this.checkForm.controls.isImported.setValue(isImported);

            if (queryParams.speciesId) {
                this.checkForm.controls.speciesId.setValue(queryParams.speciesId);
            }
            if (queryParams.tagNumber) {
                if (isImported) {
                    this.checkForm.controls.tagNumber.setValue(queryParams.tagNumber);
                } else {
                    // tslint:disable-next-line:triple-equals
                    if (queryParams.speciesId == 929555477858772) {
                        //this.tagNumberPrefix = queryParams.tagNumber.substr(0, 5);
                        this.tagNumberPrefix = queryParams.tagNumber.substr(0, 3);
                        //this.checkForm.controls.tagNumber.setValue(queryParams.tagNumber.substr(5));
                        this.checkForm.controls.tagNumber.setValue(queryParams.tagNumber.substr(3));
                    } else {
                        this.tagNumberPrefix = queryParams.tagNumber.substr(0, 4);
                        this.checkForm.controls.tagNumber.setValue(queryParams.tagNumber.substr(4));
                    }
                }
                await this.getAnimalData(queryParams.tagNumber);
            }
        }
    }

    updateValidators(id?) {
        if (id) {
            const species = this.referenceService.getReference('species', id);
            this.tagNumberPrefix = `${species.country}${species.typeCode}`;
            this.codeLength = species.codeLength;
            this.checkForm.get('tagNumber').setValidators([
                Validators.required,
                Validators.minLength(species.codeLength),
                Validators.maxLength(species.codeLength),
            ]);
            // tslint:disable-next-line:triple-equals
            if (id == 929555477858772) {
                this.checkForm.get('tagNumber').setValidators([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(12)
                ]);
                //this.tagNumberPrefix = `${species.country}00`;
                this.codeLength = 12;
            }
            this.checkForm.get('tagNumber').updateValueAndValidity();
        }
    }

    getRegionById(id: string) {
        return this.referenceService.getReference('region', id);
    }

    getRegionByLevel(id: string, level: number) {
        let region = this.getRegionById(id);
        while (region.regionLevel > level) {
            region = this.getRegionById(region.parentId);
        }
        return region;
    }

    calcAge(birthDateStr: string) {
        const birthDate = new Date(birthDateStr);
        const now = new Date();
        const months = (now.getFullYear() * 12 + now.getMonth()) - (birthDate.getFullYear() * 12 + birthDate.getMonth());
        return this.referenceService.getAgeFromMonth(months);
    }

    async updateUrl() {
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                isImported: this.checkForm.value.isImported,
                speciesId: this.checkForm.value.speciesId,
                tagNumber: this.checkForm.value.isImported
                    ? this.checkForm.value.tagNumber
                    : this.tagNumberPrefix + this.checkForm.value.tagNumber
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }

    async onSubmit() {
        const tagNumber = this.tagNumberPrefix + this.checkForm.value.tagNumber;
        await this.updateUrl();
        this.getAnimalData(tagNumber);
    }

    async getAnimalData(tagNumber: string) {
        this.animalData = null;
        this.reCaptchaV3Service.execute(this.siteKey, 'AITSAnimalCheck', async (token) => {
            this.animalData = await this.animalService.animalCheck(tagNumber, token);
            this.animalData.color = this.referenceService.getReference('color', this.animalData.colorId);
            this.animalData.breed = this.referenceService.getReference('breed', this.animalData.breedId);
            this.animalData.specie = this.referenceService.getReference('species', this.animalData.breed.speciesId);
            this.animalData.status = this.referenceService.getReference('animalStatus', this.animalData.animalStatusId);
            this.animalData.healthStatus = this.referenceService.getReference('animalHealthStatus', this.animalData.animalHealthStatusId);
            // tslint:disable-next-line:max-line-length
            this.animalData.clarifyingStatus = this.referenceService.getReference('animalClarifyingStatus', this.animalData.animalClarifyingStatusId);
            this.animalData.owners.forEach(owner => {
                owner.childRegion = this.getRegionById(owner.regionId);
                owner.parentRegion = this.getRegionByLevel(owner.childRegion.parentId, 4);
            });
            // tslint:disable-next-line:triple-equals
            if (this.animalData.animalStatusId == 1) {
                this.animalData.age = this.calcAge(this.animalData.birthDate);
            }
        }, {
            useGlobalDomain: false
        });
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
}
